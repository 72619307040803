@use './typography';
@use './animations';
@use './teaser';
@use './front';
@use './colors';

p,
button,
h1,
h2 {
  margin: 0;
  padding: 0;
}

.not-found-wrapper {
  margin: 0 var(--space-5);

  .top-part {
    margin-top: 48px;

    .back-button {
      display: flex;
      align-items: center;
      @include typography.graphik(14px, 20px, 500, 0, #000000);
      text-decoration: none;
      background-color: transparent;
      border: none;
      cursor: pointer;

      &__arrow {
        margin-right: 6px;
        transform: rotate(180deg);
        width: 18px;
        filter: invert(0%) sepia(100%) saturate(4%) hue-rotate(162deg)
          brightness(100%) contrast(101%);
      }
    }

    .main-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__illustration {
        width: 126px;
        @include animations.fadeIn(2s);

        .illustration-image {
          max-width: 100%;
          width: 100%;
          object-fit: contain;
        }
      }

      &__text {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        max-width: 480px;

        .status-code {
          margin: 36px 0 14px;
          @include typography.graphik(14px, 20px, 500, 0, var(--c-dark));
        }

        .heading {
          @include animations.fadeIn(1s);
          @include typography.mabry(
            40px,
            45px,
            700,
            -0.3px,
            var(--c-pink-medio)
          );
          max-width: 444px;
        }

        .lead {
          @include typography.graphik(17px, 25px, 400, 0, var(--c-dark));
          margin: var(--space-6) 0 var(--space-8);
        }
      }
    }
  }

  .teasers {
    margin: 68px 0;

    &__heading {
      @include typography.mabry(20px, 24px, 700, -0.2px, var(--c-dark));
      margin-bottom: var(--space-4);
    }

    &__items-wrapper {
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .to-frontpage {
    @include typography.not-a-button();
    width: fit-content;
  }

  &.phone {
    .top-part {
      margin-top: 48px;

      .main-content {
        margin-top: 52px;
      }
    }

    .teasers {
      &__items-wrapper {
        @include colors.snap-container(0, var(--space-4));

        .teaser {
          @include colors.snap-item();

          &:last-of-type {
            margin-right: var(--space-5);
          }
        }
      }
    }
    .image {
      width: 65vw;
      height: auto;
    }

    .title {
      font-size: 19px;
      line-height: 25px;
    }

    &.illustration.title {
      font-size: 21px;
      line-height: 24px;
    }
  }

  &.desktop {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 48px 0 0 0;

    .top-part {
      margin-top: 0;

      .main-content {
        margin-top: 0;
        flex-direction: row-reverse;
        justify-content: flex-end;

        &__illustration {
          width: 200px;
          height: auto;
        }

        &__text {
          text-align: left;
          align-items: flex-start;
          margin-right: 7vw;
        }
      }
    }

    .teasers {
      margin: 95px 0 60px;

      &__heading {
        @include typography.mabry(22px, 30px, 700, 0, var(--c-dark));
        margin-bottom: 26px;
      }
    }
  }
}
