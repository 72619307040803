@use './typography';

.saves-content {
  min-height: 300px;
  margin: 0 auto;
  max-width: var(--max-width);
  width: var(--article-width-desktop);
}
.saves-title {
  margin: 0 0 var(--space-8) 0;
}
.saves-list-container {
  padding: var(--space-4) 0;
  border-top: 1px solid var(--c-primary-3);
  .menu {
    z-index: 2;
    padding: var(--space-4);
    width: 200px;
    background-color: var(--c-dark);
  }
  &.active {
    background-color: var(--c-gray);
  }
}
.edit-action,
.share-action,
.delete-action {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include typography.graphik(17px, 25px, 400, 0);
  &:hover {
    cursor: pointer;
  }
}
.edit-action {
  color: var(--c-green-medio);
  padding-bottom: var(--space-5);
  &::after {
    content: '';
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/edit_pen.svg');
    background-repeat: no-repeat;
    background-position: center;
    min-width: 16px;
    min-height: 16px;
    position: relative;
  }
}
.share-action {
  color: var(--c-yellow-medio);
  padding-bottom: var(--space-5);
  &::after {
    content: '';
    filter: brightness(0) saturate(100%) invert(88%) sepia(53%) saturate(522%)
      hue-rotate(325deg) brightness(105%) contrast(100%);
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/share-grey.svg');
    background-repeat: no-repeat;
    background-position: center;
    min-width: 16px;
    min-height: 16px;
    position: relative;
  }
}
.delete-action {
  color: var(--c-pink-medio);
  &::after {
    content: '';
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/transhcan_light.svg');
    background-repeat: no-repeat;
    background-position: center;
    min-width: 16px;
    min-height: 16px;
    position: relative;
    filter: brightness(0) saturate(100%) invert(70%) sepia(98%) saturate(356%)
      hue-rotate(274deg) brightness(101%) contrast(104%);
  }
}
.saves-list {
  display: flex;
  flex-direction: column;
}
.saves-link {
  display: flex;
  text-decoration: none;
  .image {
    width: 70px;
    height: 70px;
    border-radius: var(--border-radius);
  }
  .saves-list-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-left: var(--space-3);
  }
  .saves-list-info {
    display: flex;
    align-items: center;
  }
  .saves-list-name {
    @include typography.graphik(18px, 25px, 500, 0, var(--c-dark));
    display: flex;
    align-items: center;
    &::after {
      content: '';
      margin-left: var(--space-2);
      height: 16px;
      width: 1px;
      background-color: var(--c-primary-3);
    }
  }
  .saves-list-count {
    @include typography.graphik(12px, 14px, 400, 0, var(--c-dark-gray));
    margin-left: var(--space-2);
  }
}
.dropdown-button {
  border: none;
  background-color: transparent;
  padding: var(--space-4);
  margin: -16px;
  &:hover {
    cursor: pointer;
  }
  &::before {
    content: '';
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/dropdown.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    width: 5px;
    height: 20px;
    position: relative;
    background-size: contain;
    display: block;
    &:hover {
      cursor: pointer;
    }
  }
}

.teaser-saved {
  width: 100%;
  padding: var(--space-4) var(--space-4) var(--space-5) 0;
  .teaser-link {
    display: flex;
    text-decoration: none;
    justify-content: space-between;
    width: 100%;
    &-info {
      display: flex;
      width: 100%;
      align-items: center;
    }
  }
  .image {
    border-radius: 5px;
    width: 70px;
    height: 70px;
  }
  .text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .text {
    margin-left: var(--space-3);
  }
  .teaser-kicker {
    @include typography.graphik(14px, 22px, 500, 0, var(--c-primary-2));
    margin-bottom: var(--space-1);
  }
  .teaser-title {
    @include typography.mabry(17px, 19px, 500, -0.3px, var(--c-dark));
    margin-bottom: var(--space-3);
  }
  .teaser-tags {
    @include typography.graphik(14px, 22px, 400, -0.2px, var(--c-dark-gray));
  }
  .front-grade {
    @include typography.graphik(12px, 16px, 500, 0, var(--c-primary-2));
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bold-grade {
    @include typography.graphik(16px, 20px, 500, 0, var(--c-primary-2));
    margin-bottom: var(--space-1);
  }
}

.list-content-actions {
  display: flex;
  button {
    @include typography.graphik(14px, 18px, 500, -0.2px, var(--c-dark-gray));
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }

  .share {
    margin-right: var(--space-6);
    &::before {
      content: '';
      display: inline-block;
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/share-grey.svg');
      background-repeat: no-repeat;
      background-position: center;
      width: 16px;
      height: 16px;
      margin-right: var(--space-1);
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.delete-icon {
  &::before {
    content: '';
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/transhcan_light.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    min-width: 16px;
    min-height: 16px;
    position: relative;
    margin-right: 7px;
    &:hover {
      cursor: pointer;
    }
  }
}

.saved-items-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--space-10);
  .go-back {
    margin: 0;
  }
}

.saved-items-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--space-10);

  .save-shared {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    @include typography.graphik(14px, 18px, 500, -0.2px, var(--c-dark-gray));
    &::before {
      display: inline-block;
      margin-right: var(--space-1);
      content: '';
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/bookmark.svg');
      background-repeat: no-repeat;
      background-size: 16px 16px;
      width: 16px;
      height: 16px;
      filter: var(--filter-dark-gray);
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.saved-items-count {
  @include typography.graphik(12px, 14px, 400, 0, var(--c-dark));
}

.saved-items-title,
.saves-title {
  @include typography.mabry(40px, 45px, 500, -0.2px, var(--c-primary-2));
}

.saved-item-container {
  display: flex;
  align-items: center;
  border-top: 1px solid var(--c-primary-3);
  padding-left: var(--space-4);

  &.active {
    background-color: var(--c-gray);

    .saved-item-delete-button {
      background: var(--c-primary-2);
    }
  }
}

.saved-item-delete-button {
  background: var(--c-light);
  border-radius: 50%;
  height: 12px;
  width: 12px;
  box-shadow: 0 0 0 1px var(--c-primary-2);
  border: 4px solid var(--c-light);
  padding: 6px;
  margin-right: var(--space-3);

  &:hover {
    cursor: pointer;
  }
}

.delete-buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 56px;
}

.delete-action-button {
  display: flex;
  border-radius: var(--border-radius);
  background-color: var(--c-primary-2);
  color: var(--c-light);
  border: 1px solid var(--c-primary-2);
  padding: var(--space-3) var(--space-5);
  margin-right: var(--space-10);
}

.delete-cancel-button {
  @include typography.graphik(14px, 22px, 500, -0.2, var(--c-dark));
  border: none;
  background-color: transparent;
}

.no-image-placeholder {
  display: flex;
  &::after {
    content: '';
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/no_save_icon.svg');
    width: 70px;
    height: 70px;
    background-size: contain;
    border-radius: var(--border-radius);
  }
}

.phone-front {
  .list-overview {
    padding: 0 var(--space-4);
  }
  .saves-content {
    width: 100%;
  }
  .saved-items-teasers {
    .saved-item-container:first-child {
      border-top: none;
    }
  }
  .saved-items-top {
    padding-left: var(--space-4);
    padding-right: var(--space-4);
  }
  .saved-items-title,
  .saves-title {
    @include typography.mabry(28px, 30px, 500, -0.2px, var(--c-primary-2));
  }
  .saves-link {
    margin-right: 0;
    .image,
    .no-image-placeholder::after {
      max-width: 100%;
      min-width: 70px;
      min-height: 70px;
      height: auto;
      background-size: 70px 70px;
    }
    .saves-list-name {
      @include typography.graphik(17px, 26px, 400, 0, var(--c-primary-0));
    }
  }
  .saves-title {
    font-size: 28px;
    line-height: 30px;
    margin-bottom: var(--space-4);
  }
  .teaser-saved {
    .text {
      margin-left: var(--space-3);
    }
  }
  .saves-list-container {
    border: none;
  }
}
