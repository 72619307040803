@use './typography';
@use './colors';

$teaserWidth: 222px;
$teaserWidthMedium: 306px;
$teaserWidthBig: 474px;

.teaser {
  .kicker-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--space-3) 0;
  }

  .kicker {
    @include typography.teaser-kicker();
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    padding: 4px 6px;
    background-color: var(--c-gray);
    $colors: 'pink', 'green', 'blue', 'yellow', 'godt';
    @each $color in $colors {
      &.#{$color} {
        background-color: var(--c-#{$color}-bright);
        color: var(--c-#{$color}-text);
        &::before {
          content: '';
          background-repeat: no-repeat;
          background-position: center;
          position: relative;
          width: 15px;
          height: 15px;
          background-size: contain;
          margin-right: var(--space-1);
          flex-shrink: 0;
          display: inline-flex;
        }
      }
    }
    &.blue::before {
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/sections/movies-series.svg');
    }
    &.pink::before {
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/sections/city-life-guide.svg');
    }
    &.yellow::before,
    &.godt::before {
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/sections/food-drinks.svg');
    }
  }

  .teaser-link {
    text-decoration: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #000724;
  }

  .save-button {
    background-color: transparent;
    border: none;
    display: inline-flex;
    position: relative;
    z-index: 2;
    padding: 2em 1em 2em 2em;
    margin: -2em -1em -2em -2em;

    &:hover {
      cursor: pointer;
    }
    &::after {
      content: '';
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/bookmark.svg');
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      width: 17px;
      height: 16px;
      background-size: contain;
      filter: brightness(0) saturate(100%) invert(50%) sepia(6%) saturate(62%)
        hue-rotate(349deg) brightness(88%) contrast(89%);
    }
    &.saved::after {
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/bookmark-saved.svg');
    }
  }

  .image {
    width: $teaserWidth;
    background-size: cover;
    object-fit: cover;
    border-radius: var(--border-radius);
  }

  .text-wrapper {
    padding: var(--space-) 0 30px 0;
    background: white;
    width: 100%;
  }

  .grade-tags,
  .tag-grade {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .front-grade {
    @include typography.mabry(13px, 18px, 700, 0, var(--c-dark));
    display: flex;
    align-items: center;
    & + .bottom-tags,
    & + .time-stamp {
      border-left: 1px solid var(--c-primary-3);
      padding-left: var(--space-2);
      margin-left: var(--space-2);
    }
  }

  .godt {
    & + .bottom-tags {
      border-left: 1px solid var(--c-primary-3);
      padding-left: var(--space-2);
      margin-left: var(--space-2);
    }
  }

  .grade-tags {
    .godt {
      height: 16px;
      width: auto;
    }
  }

  .tag-grade {
    .front-grade,
    .bottom-tags {
      margin-left: var(--space-2);
    }
  }

  .top-list-tag {
    margin: var(--space-3) 0;
    border-radius: var(--border-radius);
    background-color: var(--c-green-medio);
    display: inline-flex;
    padding: 4px 6px;
    @include typography.graphik(12px, 18px, 500, -0.2px, var(--c-yellow-text));
    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 6px;
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/sections/star.svg');
    }
  }

  .bottom-tags {
    @include typography.graphik(12px, 18px, 400, 0, var(--c-dark-gray));
  }
  .bold-grade {
    margin-right: var(--space-1);
  }
  .marks {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 2.5px;
    grid-row-gap: 2.5px;
  }
  .mark {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: var(--c-neutral-4);
    &.bold {
      background-color: var(--c-dark);
    }
  }

  .title {
    @include typography.mabry(27px, 32px, 500, -0.2px, var(--c-dark));
    padding-bottom: var(--space-4);
    word-break: break-word;
  }

  &:not(:last-of-type):not(.teaser-search-result) {
    margin-right: 30px;
  }

  &.big {
    width: $teaserWidthBig;
    grid-area: 1 / 1 / 3 / 2;
    margin-right: 30px;
    .image {
      width: $teaserWidthBig;
    }

    .title {
      font-size: 40px;
      line-height: 45px;
    }
  }

  &.medium {
    width: $teaserWidthMedium;

    .image {
      width: $teaserWidthMedium;
    }
  }

  &.small {
    width: $teaserWidth;
    .text-wrapper {
      padding-bottom: var(--space-5);
    }
    .title {
      font-size: 18px;
      line-height: 22px;
      padding-bottom: var(--space-3);
    }
    .kicker {
      @include typography.truncate(195px);
      &::before {
        top: 3px;
      }
    }
  }

  &.illustration {
    .teaser-link {
      align-items: center;
    }
    &.big {
      width: $teaserWidthBig;
      .image {
        margin-top: 80px;
        width: 220px;
      }
      .title {
        font-size: 40px;
        line-height: 42px;
        letter-spacing: -0.3px;
      }
    }
    &.medium {
      width: $teaserWidthMedium;
      .image {
        margin-top: 72px;
        width: 150px;
      }
      .title {
        font-size: 30px;
        line-height: 34px;
      }
    }
    &.small {
      width: $teaserWidth;
      .image {
        margin-top: 40px;
        width: 80px;
      }
      .title {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }
}

.godt-teaser {
  .image {
    height: 148px;
  }
}

// mobile
.phone-front .teaser,
.mobile-article .teaser {
  width: auto;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: flex-start;

  &.big {
    margin: 0 var(--space-5) var(--space-5) var(--space-5);
    &.illustration {
      height: 410px;
      .image {
        margin-top: 0px;
      }
    }
  }

  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &.godt-teaser {
    .image {
      max-height: 250px;
    }
  }

  .title {
    font-size: 27px;
    line-height: 32px;
  }
  &.illustration {
    width: auto;
    .title {
      font-size: 30px;
      line-height: 34px;
    }
  }
  &.illustration {
    .text-wrapper {
      position: relative;
    }
  }
}

.phone-front,
.mobile-article {
  .bundle {
    padding-bottom: var(--bottom-space-small);
  }
  .big {
    .title {
      padding-bottom: var(--space-3);
    }
  }
  .big .bundle-teasers {
    margin-left: var(--space-5);
    margin-right: var(--space-5);

    flex-direction: column;

    &.wrapped {
      display: grid;
      grid-auto-columns: minmax(0, 1fr);
      grid-auto-flow: column;
      grid-column-gap: var(--space-4);
      margin-right: var(--space-5);
    }
    .teaser {
      &:last-of-type {
        margin-right: var(--space-5);
      }
      &:not(:last-of-type) {
        margin-right: 0px;
      }

      .image {
        height: auto;
        object-fit: cover;
      }

      &.big {
        margin: 0;
        .title {
          font-size: 27px;
          line-height: 32px;
        }
      }
      &.medium {
        margin-right: 0;
        .kicker {
          @include typography.truncate(35vw);
          &::before {
            top: 3px;
          }
        }
        .title {
          padding-bottom: var(--space-3);
          font-size: 18px;
          line-height: 22px;
        }
      }
      &.small {
        width: 100%;
        .teaser-link {
          flex-direction: row;
          padding-bottom: var(--space-5);
        }
        .text-wrapper {
          padding-bottom: 0;
        }
        .kicker-wrapper {
          padding-top: 0;
        }
        .kicker {
          @include typography.truncate(45vw);
          &::before {
            top: 3px;
          }
        }
        .image {
          width: 80px;
          height: 80px;
          margin-right: var(--space-3);
        }
        .title {
          font-size: 18px;
          line-height: 22px;
          padding-bottom: 0;
        }
        &:first-child {
          margin-top: 10px;
        }
      }
      &.illustration.small  {
        .image {
          height: 100px;
        }
      }

      &.illustration.title {
        font-size: 21px;
        line-height: 24px;
      }
    }
  }

  .bundle-teasers .small.illustration {
    .image {
      width: 180px;
      height: 180px;
    }
  }
}
.tablet-front,
.tablet-article-container {
  .teaser {
    .image {
      width: 100%;
    }
  }
  .teaser.big {
    max-width: 474px;
    width: 100%;
    margin: 0;
  }
  .teaser.medium {
    max-width: 309px;
    width: 100%;
  }
  .teaser.small {
    max-width: 222px;
    min-width: 160px;
    width: 100%;
  }
}
.tablet-front {
  .teaser.small {
    .kicker {
      max-width: clamp(126px, -4px + 19vw, 170px);
    }
  }
}
