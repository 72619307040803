@use './typography';

.confirm-overlay {
  .modal {
    padding: var(--space-6);
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &-title {
    @include typography.mabry(20px, 22px, 500, -0.2px, var(--c-primary-2));
    margin-bottom: var(--space-3);
  }
  &-prompt {
    @include typography.graphik(18px, 26px, 400, 0, var(--c-dark));
    margin-bottom: var(--space-4);
  }
  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .confirm-overlay-action-button {
      display: flex;
      border-radius: var(--border-radius);
      width: fit-content;
      background-color: var(--c-primary-2);
      color: white;
      border: 1px solid var(--c-primary-2);
      padding: var(--space-3) var(--space-5);
      &:disabled {
        border: 1px solid var(--c-dark-gray);
        color: var(--c-dark-gray);
        background-color: var(--c-gray);
      }
    }
    .confirm-overlay-cancel-button {
      @include typography.graphik(14px, 22px, 500, -0.2, var(--c-dark));
    }
  }
  input {
    display: flex;
    margin-bottom: var(--space-4);
    padding: var(--space-3);
    border: 2px solid var(--c-neutral-6);
    border-radius: 4px;
    font-size: 18px;
    height: 42px;
    &:focus,
    &:focus-within,
    &:focus-visible,
    &:active {
      outline: none;
      border: 2px solid var(--c-primary-2);
    }
  }

  &.shared {
    .modal-title {
      @include typography.mabry(28px, 30px, 700, -0.2px, var(--c-primary-2));
    }
    .confirm-overlay-prompt {
      @include typography.graphik(17px, 25px, 400, 0, var(--c-dark));
    }
    input {
      width: 100%;
    }
    .confirm-overlay-action-button {
      margin: auto;
    }
  }
}

.phone {
  .confirm-overlay .modal {
    min-width: auto;
    width: 80vw;
  }
}
