@use './typography';
@use './colors';

.homescreen-button {
  display: block;
  width: 179px;
  border-radius: var(--space-1);
  text-decoration: none;
  margin: 70px auto 40px auto;
  background-color: var(--c-primary-2);
  @include typography.graphik(15px, 19px, 500, -0.3px, var(--c-light));
  padding: var(--space-4) var(--space-6);

  &:hover {
    text-decoration: underline;
  }
}

.godt-redirect-button {
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 179px;
  text-align: center;
  text-decoration: none;
  margin: 0 auto 40px auto;
  @include typography.graphik(16px, 16px, 500, -0.3px, var(--c-godt-red));

  &:hover {
    text-decoration: underline;
  }

  &::after {
    content: '';
    display: inline-block;
    align-self: end;
    width: 16px;
    height: 15px;
    background-image: url('https://cdn.aftenposten.no/vink/images/icons/arrows/arrow_dark_red.svg');
    background-repeat: no-repeat;
    background-size: 16px 15px;
  }
}

.soMe-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0px auto 52px auto;
  &__title {
    @include typography.graphik(19px, 25px, 500, -0.3px, var(--c-dark));
  }
  &__body {
    display: flex;
    gap: 24px;
    align-items: center;
  }
  &__circle {
    display: flex;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #000724;
  }
  .redirect-soMe {
    position: relative;
    &::after {
      content: '';
      height: 20px;
      width: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('https://cdn.aftenposten.no/vink/images/icons/facebookSquare.svg')
        no-repeat center / 20px 20px;
    }
    &.instagram {
      &::after {
        background-image: url('https://cdn.aftenposten.no/vink/images/icons/instagramLight.svg');
      }
    }
    &.tiktok {
      &::after {
        background-image: url('https://cdn.aftenposten.no/vink/images/icons/tiktok.svg');
      }
    }
  }
}
.homescreen-button-centered {
  @extend .homescreen-button;
  margin-bottom: 70px;
}
.go-back {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  padding: var(--space-2);
  margin: 0 0 var(--space-16) var(--space-4);

  &:hover {
    cursor: pointer;
  }

  .icon {
    margin-right: var(--space-2);

    &::before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 10px;
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/arrows/arrow_blue.svg');
      background-repeat: no-repeat;
      background-size: 12px 10px;
      rotate: 180deg;
    }
  }

  .label {
    @include typography.graphik(18px, 18px, 400, 0, var(--c-primary-2));
  }
}
