@use '../../aftenposten/scss/styles-common/cdn-fonts';
@use './fonts';

// fallbacks
$fontSerif: Georgia, Times, 'Times New Roman', serif;
$fontSans: Arial, Helvetica, sans-serif;
$fontSansBold: 'Arial-Bold', $fontSans;

$fontGraphik: Graphik, $fontSans;
$fontGraphikXCond: GraphikXCond, $fontSansBold;
$fontPublico: Publico, $fontSerif;
$fontPublicoBold: 'Publico-Bold', $fontSerif;
$fontPublicoHeadline: Publico Headline, $fontSerif;

$fontProdukt: Produkt, $fontSans;
$fontProduktSemibold: 'Produkt-Semibold', $fontSans;
$fontProduktXXCond: ProduktXXCond, $fontSerif;
$fontMabry: Mabry, $fontSerif;
$margin-desktop: 100px;
$margin-mobile: 20px;

@mixin label-1($color: var(--c-dark), $weight: normal) {
  font-family: $fontGraphik;
  font-size: 14px;
  line-height: 16px;
  color: $color;
  font-weight: $weight;
  letter-spacing: 0;
}
@mixin label-2($color: var(--c-dark), $weight: normal) {
  font-family: $fontGraphik;
  font-size: 12px;
  line-height: 16px;
  color: $color;
  font-weight: $weight;
  letter-spacing: 0;
}

@mixin label-3($color: var(--c-dark), $weight: normal, $style: normal) {
  font-family: $fontPublico;
  font-size: 14px;
  font-style: $style;
  font-weight: $weight;
  line-height: 18px;
  color: $color;
}
@mixin article-title {
  font-family: $fontGraphik;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43px;
}

@mixin article-title-mobile {
  font-family: $fontGraphik;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.3px;
}

@mixin lead-text {
  font-family: $fontGraphik;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 29px;
}

@mixin lead-text-mobile {
  font-family: $fontGraphik;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

@mixin graphik($fs, $lh, $fw, $ls: 0, $color: var(--c-primary-0)) {
  font-family: $fontGraphik;
  font-size: $fs;
  line-height: $lh;
  color: $color;
  font-weight: $fw;
  letter-spacing: $ls;
}

@mixin publicoHeadline($fs, $lh, $fw, $ls: 0) {
  font-family: $fontPublicoHeadline;
  font-size: $fs;
  line-height: $lh;
  color: var(--c-dark);
  font-weight: $fw;
  letter-spacing: $ls;
}

@mixin teaser-kicker() {
  font-family: $fontGraphik;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: -0.2px;
}

@mixin not-a-button() {
  border-radius: var(--border-radius);
  text-decoration: none;
  padding: 14px 24px;
  @include graphik(14px, 19px, 400, -0.3px, var(--c-dark));
  padding: var(--space-2) var(--space-6);
  border: 1px solid var(--c-dark);
  border-radius: var(--border-radius);
}

@mixin mabry($fs, $lh, $fw, $ls: 0, $color: var(--c-dark)) {
  font-family: $fontMabry;
  font-size: $fs;
  line-height: $lh;
  color: $color;
  font-weight: $fw;
  letter-spacing: $ls;
}

@mixin truncate($percentage) {
  text-overflow: ellipsis;
  display: inline-block;
  max-width: $percentage;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
}
