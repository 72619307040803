$cdnUrl: 'https://cdn.aftenposten.no/vink/fonts/';

@mixin cdn-font-face($family, $path, $filename, $weight: null, $style: null) {
  @font-face {
    font-family: $family;
    src: local($family), local('$filename'),
      url($cdnUrl + $path + $filename + '.woff2') format('woff'),
      url($cdnUrl + $path + $filename + '.woff') format('woff');
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin mabry($name, $weight, $style: null) {
  @include cdn-font-face(
    'Mabry',
    'mabry/',
    'Mabry' + $name + '-Pro',
    $weight,
    $style
  );
}

@include mabry('-Bold', 700);
@include mabry('-Medium', 500);
@include mabry('-MediumItalic', 500, 'italic');
