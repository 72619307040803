:root {
  --c-light: #ffffff;
  --c-dark: #000724;

  --space-1: 4px;
  --space-2: calc(var(--space-1) * 2);
  --space-3: calc(var(--space-1) * 3);
  --space-4: calc(var(--space-1) * 4);
  --space-5: calc(var(--space-1) * 5);
  --space-6: calc(var(--space-1) * 6);
  --space-7: calc(var(--space-1) * 7);
  --space-8: calc(var(--space-1) * 8);
  --space-10: calc(var(--space-1) * 10);
  --space-12: calc(var(--space-1) * 12);
  --space-13-5: calc(var(--space-1) * 13.5);
  --space-14: calc(var(--space-1) * 14);
  --space-16: calc(var(--space-1) * 16);
  --space-18: calc(var(--space-1) * 18);

  --bottom-space: 40px;
  --bottom-space-small: 20px;

  --box-shadow: 0px 1px 2px rgba(38, 0, 0, 0.31);
  --max-width: 978px;
  --search-width: 537px;
  --article-width-desktop: 784px;

  --border-radius: 4px;

  // new colors
  --c-primary-0: #151e39;
  --c-primary-1: #dfe3fc;
  --c-primary-2: #002277;
  --c-primary-3: #f0eeeb;
  --c-neutral-2: #e9e9e9;
  --c-neutral-4: #bfbfbf;
  --c-neutral-5: #a3a3a3;
  --c-neutral-6: #767676;
  --c-neutral-7: #656565;
  --c-neutral-9: #333333;
  --c-gray: #f9f9f7;
  --c-dark-gray: #757473;
  --c-primary-3-10: rgba(240, 238, 235, 0.1);

  --c-blue-bright: #c7ecff;
  --c-blue-medio: #8dd1ff;
  --c-blue-text: #001133;
  --c-pink-bright: #ffd4fc;
  --c-pink-medio: #ff9ffa;
  --c-pink-text: #330f2c;
  --c-green-bright: #d8ffb3;
  --c-green-medio: #cbf995;
  --c-green-text: #113311;
  --c-yellow-bright: #ffffa9;
  --c-yellow-medio: #ffed80;
  --c-yellow-text: #261d04;
  --c-ap-blue: #0533ff;
  --c-ap-red: #d44516;
  --c-godt-red: #632626;
  --c-godt-bright: #ffbed8;
  --c-godt-text: #261d04;

  --default-font-family: 'Graphik';

  --filter-light: brightness(0) invert(1);
  --filter-blue: brightness(0) saturate(100%) invert(10%) sepia(84%)
    saturate(3509%) hue-rotate(221deg) brightness(86%) contrast(107%);

  --filter-red: brightness(0) saturate(100%) invert(40%) sepia(49%)
    saturate(3585%) hue-rotate(350deg) brightness(83%) contrast(99%);

  --filter-dark: brightness(0) saturate(100%) invert(8%) sepia(17%)
    saturate(4321%) hue-rotate(199deg) brightness(86%) contrast(113%);

  --filter-pink: brightness(0) saturate(100%) invert(80%) sepia(27%)
    saturate(1134%) hue-rotate(275deg) brightness(104%) contrast(103%);

  --filter-pink-text: brightness(0) saturate(100%) invert(10%) sepia(45%)
    saturate(1528%) hue-rotate(271deg) brightness(87%) contrast(101%);

  --filter-green: brightness(0) saturate(100%) invert(87%) sepia(4%)
    saturate(3775%) hue-rotate(42deg) brightness(109%) contrast(95%);

  --filter-green-text: brightness(0) saturate(100%) invert(15%) sepia(8%)
    saturate(4179%) hue-rotate(72deg) brightness(91%) contrast(93%);
  --filter-yellow: brightness(0) saturate(100%) invert(96%) sepia(55%)
    saturate(888%) hue-rotate(322deg) brightness(99%) contrast(115%);
  --filter-blue-medio: brightness(0) saturate(100%) invert(69%) sepia(62%)
    saturate(448%) hue-rotate(179deg) brightness(102%) contrast(103%);
  --filter-dark-gray: brightness(0) saturate(100%) invert(48%) sepia(4%)
    saturate(121%) hue-rotate(349deg) brightness(92%) contrast(89%);
  --filter-neutral-sand: brightness(0) saturate(100%) invert(99%) sepia(1%)
    saturate(678%) hue-rotate(324deg) brightness(98%) contrast(91%);
}

@mixin snap-container($ml: var(--space-5), $sp: var(--space-4)) {
  flex-direction: row;
  margin-left: $ml;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 0 0 $sp;
  -webkit-overflow-scrolling: touch;
}

@mixin snap-item() {
  display: inline-flex;
  position: relative;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}
@mixin line-clamp($lines: 2) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: var(--space-1);
}
