@use './typography';

.skeleton-overview,
.skeleton-list {
  width: var(--article-width-desktop);
  .skeleton-title {
    @include typography.mabry(40px, 42px, 500, -0.3px, var(--c-dark));
    margin-bottom: 37px;
  }
  .head {
    height: 60px;
    width: 150px;
    background-color: var(--c-gray);
    margin-bottom: 44px;
  }
  .line {
    height: 70px;
    width: 100%;
    background-color: var(--c-gray);
    margin-bottom: 21px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--space-10);
    div {
      width: 90px;
      height: 37px;
      background-color: var(--c-gray);
    }
  }
  .skeleton-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 37px;
  }
  .skeleton-count {
    width: 34px;
    height: 14px;
    background-color: var(--c-gray);
  }
  .skeleton-list-title {
    @include typography.mabry(40px, 45px, 500, -0.2px, var(--c-primary-2));
  }
}

.phone {
  .skeleton-overview,
  .skeleton-list {
    width: 100%;
    padding: 0 var(--space-4);
    .head {
      height: 40px;
    }
    .line {
      height: 54px;
    }
    .skeleton-list-title {
      font-size: 28px;
      line-height: 30px;
    }
  }
}
