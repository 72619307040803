@use './typography';

.overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 4;
  background-color: #0007244d;
  button {
    @include typography.graphik(14px, 20px, 400);
    background-color: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
  .modal {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    padding: 50px 20px;
    min-width: 390px;
  }

  .modal-title {
    @include typography.mabry(28px, 30px, 500, -0.2px, var(--c-dark));
    margin: var(--space-1) auto var(--space-4) auto;
    display: flex;
    justify-content: center;
  }
  .modal-close {
    padding: 0;
    @include typography.graphik(14px, 18px, 500, 0, var(--c-dark));
    display: flex;
    width: 100%;
    justify-content: end;
  }
}

.overlay.dark {
  &-login {
    height: 100%;
    min-height: 100vh;
    background-color: #f9f9f7b3;
    .modal {
      background-color: var(--c-dark);
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: var(--space-8);
      max-width: 340px;
      .overlay-logo img {
        width: 108px;
        height: 40px;
        margin-bottom: var(--space-10);
        filter: brightness(0) saturate(100%) invert(94%) sepia(14%)
          saturate(950%) hue-rotate(356deg) brightness(105%) contrast(105%);
      }
      .nudge {
        display: flex;
        flex-direction: column;
        align-items: center;
        &::before {
          content: '';
          background-image: url('https://cdn.aftenposten.no/vink/images/illustrations/pasta-man.svg');
          width: 100px;
          height: 100px;
          margin-bottom: var(--space-10);
        }
        @include typography.mabry(21px, 24px, 500, -0.2px, white);
        margin-bottom: var(--space-6);
      }
      .action-button {
        text-decoration: none;
        background-color: var(--c-pink-medio);
        padding: 14px 24px;
        color: var(--c-pink-text);
        @include typography.graphik(14px, 20px, 600);
        border-radius: var(--border-radius);
      }
      .close-overlay {
        position: absolute;
        right: var(--space-5);
        top: var(--space-5);
        color: var(--c-gray);
      }
    }
  }
}

.mobile-article,
.phone-front {
  .overlay.overlay.dark-login .modal {
    min-width: 80vw;
  }
}

.phone-front,
.guide-article.phone,
.review-article.mobile-article {
  .overlay.dark {
    &-login {
      .modal {
        min-width: 90vw;
        max-width: 100%;
      }
    }
  }
  .modal {
    .modal-close {
      justify-content: center;
      font-size: 0;
      &::after {
        content: '';
        width: 48px;
        height: 7px;
        background-color: var(--c-primary-2);
        border-radius: 41px;
        margin-top: var(--space-3);
        margin-bottom: var(--space-6);
      }
    }
  }
}
