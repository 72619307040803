@use './typography';

.overlay.toast {
  background-color: transparent;

  .modal {
    padding: 0;
  }

  .toast {
    display: flex;
    align-items: center;
    padding: 18px;
    background-color: var(--c-green-bright);
    left: 0;
    @include typography.graphik(14px, 20px, 600, 0, var(--c-dark));
    text-align: center;

    &::before {
      content: '';
      background-image: url('https://cdn.aftenposten.no/vink/images/icons/checkmark.svg');
      width: 18px;
      height: 17px;
      background-repeat: no-repeat;
      margin-right: var(--space-2);
    }

    &.error {
      background-color: var(--c-pink-medio);
      color: var(--c-pink-text);

      &::before {
        content: '';
        background-image: url('https://cdn.aftenposten.no/vink/images/icons/pinkx.svg');
        width: 19px;
        height: 19px;
        background-repeat: no-repeat;
        margin-right: var(--space-2);
      }
    }
  }
}
