@use './typography';

.list-overview,
.list-options {
  .create-new-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include typography.graphik(14px, 18px, 500, 0, var(--c-primary-2));
    border-bottom: 1px solid var(--c-gray);
    padding: var(--space-4);
    border: none;
    background-color: transparent;

    &::before {
      content: '+';
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--c-primary-2);
      font-size: 34px;
      border: 1px dashed var(--c-primary-2);
      border-radius: var(--border-radius);
      margin-right: var(--space-3);
    }
    &:hover::before {
      border-color: var(--c-primary-2);
    }
  }
  .create-list-title {
    @include typography.graphik(19px, 25px, 500, 0, var(--c-dark));
    margin-bottom: var(--space-3);
  }

  .create-list-button-container {
    display: flex;
    justify-content: space-between;
    margin: var(--space-6) 0;
    align-items: center;
  }
  .create-list-button {
    display: flex;
    border-radius: var(--border-radius);
    width: fit-content;
    background-color: var(--c-primary-2);
    color: var(--c-light);
    border: 1px solid var(--c-primary-2);
    padding: var(--space-3) var(--space-5);

    &:disabled {
      border: 1px solid var(--c-dark-gray);
      color: var(--c-dark-gray);
      background-color: var(--c-gray);
    }
  }
  .create-list-cancel-button {
    @include typography.graphik(14px, 22px, 500, -0.2, var(--c-dark));
    border: none;
    background-color: transparent;
  }
  .create-list-input {
    display: flex;
    flex-direction: column;
    input {
      font-size: 18px;
      padding: var(--space-3);
      border: 2px solid var(--c-neutral-6);
      border-radius: 4px;
      &:focus,
      &:focus-within,
      &:focus-visible,
      &:active {
        outline: none;
        border: 2px solid var(--c-primary-2);
      }
    }
  }
}

.saves-content .list-overview .create-new-list {
  padding-left: 0;
}
