@use './typography';
@use './colors';
@use '../../core/scss/data-controller';

* {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  position: relative;
  cursor: default;
  height: 100%;
}

body {
  font-size: 100%;
  min-height: 100%;
  margin: 0;
  width: 100vw;
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;
}

#rootComponent {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

:root {
  color: var(--primary-color-1);
  font-family: typography.$fontGraphik;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  color-scheme: only light;
}

.desktop-front {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.byfunn-container {
  margin: 0;
  .desktop-front {
    padding-top: 150px;
  }
  .tablet-font {
    padding-top: 200px;
  }
}

.phone-front {
  padding-top: 90px;
}

// tablet
.tablet-front {
  margin: 180px 16px 0 16px;
  &.section {
    margin-top: 200px;
  }
  #data-controller-permanent {
    width: 100vw;
    margin: 0 -16px 0 -16px;
  }
}

.tablet-article-container {
  .article-container,
  .content-container {
    .article-image {
      padding-left: 50px;
      padding-right: 50px;
      img {
        width: 100%;
      }
    }
  }

  .listicle-group .article-image img {
    width: 100%;
  }

  .guide-article .content-container {
    width: 100%;
    max-width: var(--article-width-desktop);
  }
}

#data-controller-permanent {
  position: static;
}

// Stories overlay from @vgtv/stories-overlay
.stories-overlay {
  z-index: 9999;
}
