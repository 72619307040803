@use './typography';

.chat {
  width: 100%;
  margin: 32px auto;
  padding: 0;

  @media (min-width: 765px) {
    background-color: #f9f9f7;
    margin: 64px auto;
    padding: 48px 0;
  }
  .vinkomat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 468px;
    margin: auto;
    width: 100%;
    background-color: var(--c-green-bright);
    padding: var(--space-4) var(--space-4) var(--space-6) var(--space-4);

    @media (min-width: 765px) {
      border-radius: var(--space-2);
    }
    .icon-img {
      height: 16px;
      width: 16px;
      filter: var(--filter-blue);
    }
    .pencil-icon {
      align-self: end;
      margin-left: var(--space-2);
      margin-bottom: 6px;
    }

    .vinkomat-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: var(--space-6);

      &__content {
        display: flex;
        gap: var(--space-2);

        .content-icon {
          width: 24px;
          height: 24px;
        }

        .title {
          @include typography.mabry(23px, 120%, 500, -0.3px, var(--c-dark));
          margin: 0;
        }
      }
      &__close-button {
        display: flex;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
        margin-left: auto;

        .close-button-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    &__chat-window {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      border-radius: var(--space-4);
      background-color: white;
      padding: var(--space-6) var(--space-4) var(--space-6) var(--space-4);
      margin-bottom: var(--space-6);

      .input-wrapper {
        display: flex;
        align-items: end;
        gap: 8px;

        &__container {
          display: flex;
          align-items: center;
          width: 100%;
          border-bottom: 1px solid var(--c-primary-2);
          padding-bottom: 18px;

          .text-field {
            min-height: 28px;
            overflow-y: hidden;
            resize: none;
            width: 100%;
            border: none;
            outline: none;
            padding: 0;
            font-size: 16px;
            color: var(--c-dark);
            word-wrap: break-word;
            @include typography.graphik(16px, 25px, 400, 0, var(--c-primary-2));

            &::placeholder {
              color: var(--c-primary-2);
            }
            &:focus {
              outline: none;
            }
          }
        }
      }

      .suggestions {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin: var(--space-6) 0;

        &__buttons {
          all: unset;

          @include typography.graphik(
            15px,
            19px,
            400,
            -0.3px,
            var(--c-primary-2)
          );

          margin: 0;
          display: flex;
          align-items: center;
          gap: 8px;
          align-items: center;
          white-space: nowrap;
          border: 1px solid var(--c-primary-2);
          padding: var(--space-2) var(--space-3);
          height: 26px;
          border-radius: 52px;

          background: linear-gradient(
            to right,
            var(--c-primary-2) 50%,
            white 50%
          );
          background-size: 200% 100%;
          background-position: right bottom;

          .tag-pluss {
            transition: transform 0.2s ease-in-out;
          }

          &:hover {
            color: var(--c-light);
            background-position: left bottom;
            cursor: pointer;
            .tag-pluss {
              filter: var(--filter-light);
            }
          }
          &.selected {
            background: var(--c-primary-2);
            color: var(--c-light);
            .tag-pluss {
              transform: rotate(45deg);
              filter: var(--filter-light);
            }
          }

          // Desktop not tablet
          @media (min-width: 768px) {
            transition: background-position 0.2s ease-out;
          }
        }
      }

      .searching {
        &__searched {
          border-bottom: 1px solid var(--c-primary-2);

          .search-result {
            margin: 0 0 18px 0;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0;
            @include typography.graphik(16px, 25px, 400, 0, var(--c-primary-2));
          }
        }

        &__icon {
          display: flex;
          justify-content: center;
          margin: var(--space-6) auto var(--space-4) auto;
          width: 120px;
          height: 120px;
        }

        &__info {
          @include typography.graphik(15px, 25px, 500, -0.2px, var(--c-dark));
          display: flex;
          justify-content: center;
          margin: var(--space-2) 0;
        }
      }

      .messages {
        display: flex;
        flex-direction: column;
        padding: 0 var(--space-1) var(--space-4) 0;

        &__container {
          display: flex;
          align-items: center;
          width: 100%;
          max-width: 100%;
          justify-content: flex-end;

          .message {
            max-width: 100%;
            margin: var(--space-6) 0 0 0;

            .answer {
              margin: 0 0 var(--space-4) 0;
              padding: 0;
              @include typography.graphik(17px, 25px, 400, 0, var(--c-dark));
            }
          }
        }
      }
    }

    .disclaimer {
      @include typography.graphik(12px, 20px, 400, 0, var(--c-dark));
      display: flex;
      align-items: center;
      gap: var(--space-1);
      margin: var(--space-4) 0;

      &::before {
        height: 24px;
        width: 24px;
        content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><path d="M12 2.438A9.562 9.562 0 1 0 21.563 12 9.574 9.574 0 0 0 12 2.437Zm0 18A8.438 8.438 0 1 1 20.438 12 8.447 8.447 0 0 1 12 20.438Zm-.563-7.688V7.5a.563.563 0 0 1 1.126 0v5.25a.563.563 0 0 1-1.126 0Zm1.5 3.375a.937.937 0 1 1-1.874 0 .937.937 0 0 1 1.874 0Z" fill="%23343330"/></svg>');
      }
    }
    .send-button {
      @include typography.graphik(14px, 20px, 500, 0, var(--c-light));
      display: flex;
      align-items: center;
      align-self: end;
      justify-content: space-between;
      max-width: 78px;
      width: 100%;
      gap: 8px;
      background-color: var(--c-primary-2);
      border: none;
      cursor: pointer;
      padding: 12px 16px;
      border-radius: 24px;

      img {
        height: 12px;
        width: 12px;
        filter: invert(1);
      }
      &:disabled {
        &:hover {
          cursor: default;
        }
        opacity: 0.2;
      }
      &__secondary {
        color: var(--c-primary-2);
        border: 1px solid var(--c-primary-2);
        background-color: white;
        max-width: max-content;
        margin-left: auto;
        margin-bottom: 16px;
      }
    }

    .chat-footer {
      .disclaimer {
        display: flex;
        justify-content: center;
        color: #757473;
        margin: 0 0 var(--space-6) 0;
        &::before {
          filter: brightness(0) saturate(100%) invert(51%) sepia(0%)
            saturate(0%) hue-rotate(194deg) brightness(96%) contrast(88%);
        }
      }
      .contact-info {
        text-align: center;
        border-top: 1px solid var(--c-dark);
        padding-top: var(--space-4);

        &__link {
          color: var(--c-primary-2);
        }
      }
    }

    .recommendations {
      &__label {
        margin: 0 0 var(--space-6) 0;
        @include typography.mabry(15px, 1.1, 500, -0.2px, var(--c-dark));
      }
      &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        overflow-x: scroll;
        scrollbar-width: none;
        display: flex;
        gap: var(--space-4);

        @media (min-width: 765px) {
          overflow: hidden;
          flex-direction: column;
          align-items: flex-start;
          gap: 0;
          li {
            &:not(:first-child) {
              margin-top: var(--space-6);
            }
          }
        }
      }

      .recommendation-card {
        text-decoration: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: var(--space-3);
        width: 265px;

        &__image {
          background-color: #f9f9f7;
          margin: 0;
          padding: 0;
          max-width: 265px;
          width: 100%;
          height: 100%;
          flex-shrink: 0;
          aspect-ratio: 3/2;
          overflow: hidden;

          img {
            border-radius: 2px;
            width: 100%;
            height: 100%;
          }
        }
        &__body {
          display: flex;
          flex-direction: column;
          gap: var(--space-3);
          .kicker-wrapper {
            padding: 0;
          }
        }
        &__title {
          margin: 0;
          @include typography.graphik(21px, 29px, 500, -0.3px, var(--c-dark));
        }
        @media (min-width: 765px) {
          flex-direction: row;
          max-width: 410px;
          width: 100%;

          &__body {
            gap: var(--space-2);
          }
          &__image {
            width: 141px;
            height: 141px;
            aspect-ratio: 1/1;
          }
        }
      }
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--space-1);
      padding: var(--space-4) 0 var(--space-2) 0;

      &__dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin: 0 4px;
        background-color: var(--c-green-medio);
        border-radius: 50%;
        animation: pulse 1.2s infinite ease-in-out;

        &:nth-child(1) {
          animation-delay: 0s;
        }
        &:nth-child(2) {
          animation-delay: 0.3s;
        }
        &:nth-child(3) {
          animation-delay: 0.6s;
        }
      }

      @keyframes pulse {
        0%,
        100% {
          transform: scale(1);
          background: var(--c-green-medio);
        }
        50% {
          transform: scale(1.6);
          background: var(--c-green-text);
        }
      }
    }
  }
}
