@use './typography';

.footer {
  width: 100%;
  background-color: var(--c-dark);
  margin-top: auto;
  min-height: 396px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 86px;
  padding-bottom: 86px;
  @include typography.graphik(15px, 24px, 400, 0, var(--c-gray));

  p {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
    color: var(--primary-color-1);
  }

  &_container {
    display: flex;
    justify-content: space-between;
    width: var(--max-width);
    height: 100%;
  }

  &_section {
    display: flex;
    flex-direction: column;

    &.first {
      max-width: 277px;
    }

    .instagram {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: var(--c-pink-medio);

      img {
        margin-left: 10px;
      }
    }

    &.logo {
      justify-content: space-between;

      .logo {
        width: 108px;
        height: 40px;
      }
    }
    .icon-wrapper {
      display: flex;
      justify-content: space-between;
      width: 120px;
      margin-bottom: 18px;
    }
  }

  &_tips {
    display: inline-block;

    a {
      padding: 9px 24px;
      background-color: transparent;
      border: 1px solid var(--c-gray);
      border-radius: 4px;
    }
  }

  .yellow {
    color: var(--c-yellow-medio);
  }

  .contact {
    display: flex;
    flex-direction: column;
    color: var(--c-blue-medio);
  }
}
.tablet-front,
.tablet-article-container {
  .footer {
    width: 100vw;
    margin: auto -16px 0 -16px;

    &_container {
      padding: 0 var(--space-4);
    }
  }
}

.phone-front,
.mobile-article {
  .footer {
    padding: 35px 20px 108px 20px;

    &_container {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &_section {
      &.logo {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 50px;
      }

      .icon-wrapper {
        flex-direction: row;
        justify-content: space-between;
        width: 113px;
        margin-top: 50px;
        margin-bottom: 30px;
      }

      .instagram {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    &_tips {
      padding: 8px 16px;
      @include typography.graphik(14px, 17px, 400, -0.3px, var(--c-gray));
    }
  }
}
